@import "variables.scss";

html,
body {
  height: 100%;
  --cor-bg-principal: #18bfff;
  --fx-gap: 16px;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* -- START WARNING-BOX -- */
.warning-box {
  color: rgb(0, 0, 0);
  background-color: rgb(251, 233, 231);

  font-family: "Roboto";
  font-size: 13px;

  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
}

.info-box {
  color: rgb(0, 0, 0);
  background-color: rgb(254, 250, 212);

  font-family: "Roboto";
  font-size: 13px;

  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
}

/* -- END WARNING-BOX -- */

/* -- START FLEX -- */
.flex-row-center {
  display: flex;
  justify-content: center;
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* -- END FLEX -- */

/* -- START MAT SNACK BAR -- */
.success.mat-snack-bar-container {
  color: $clr-white !important;
  background-color: $clr-green-400 !important;
}

.error.mat-snack-bar-container {
  color: $clr-white !important;
  background-color: $clr-warn !important;
}

.warning.mat-snack-bar-container {
  color: $clr-white !important;
  background-color: $clr-warning !important;
}

/* -- END MAT SNACK BAR -- */

/* -- START TYPOGRAPHY -- */
.extra-large-text-bold {
  font-family: Roboto !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 42px !important;
  letter-spacing: 0em !important;
}

.extra-large-text-regular {
  font-family: Roboto !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 42px !important;
  letter-spacing: 0em !important;
}

.large-text-bold {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
}

.large-text-regular {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 28px !important;
}

.medium-text-bold {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 25.2px !important;
}

.medium-text-regular {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 25.2px !important;
}

.normal-text-bold {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.normal-text-regular {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.small-text-regular {
  font-family: Roboto !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
}

.small-text-bold {
  font-family: Roboto !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
}

/* -- END TYPOGRAPHY -- */

/* START DIALOG */
.delete-dialog .mat-dialog-container {
  width: 500px;
}
.simple-dialog {
  width: 450px;
}
.import-dialog .mat-dialog-container {
  height: 600px;
  width: 1150px;

  .mat-dialog-content {
    max-height: inherit;
  }
}

.extension-dialog {
  width: 550px;
}

.select-pbx-dialog .mat-dialog-container {
  width: 500px;
}

.cluster-dialog .mat-dialog-container {
  width: 500px;
}

.terminal-dialog .mat-dialog-container {
  width: 95vw;
  min-width: 500px;
  min-height: 600px;
  height: 95vh;
}

.partner-dialog .mat-dialog-container {
  width: 650px;
  min-height: 220px;
}

.company-dialog .mat-dialog-container {
  width: 700px;
  min-height: 550px;
}

.advanced-filter-dialog .mat-dialog-container {
  width: 650px;
}

.equipment-dialog .mat-dialog-container {
  width: 650px;
  min-height: 220px;
}

.equipment-view-dialog .mat-dialog-container {
  width: 100vw;
}

.groups-dialog .mat-dialog-container {
  width: 650px;
  min-height: 220px;
}

.secure-confirmation-dialog .mat-dialog-container {
  width: 750px;
  height: 450px;
  padding: 0px;
}

.user-dialog .mat-dialog-container {
  width: 650px;
}

.pbx-dialog .mat-dialog-container {
  width: 600px;
  padding-bottom: 40px;
}

.confirm-change-dialog .mat-dialog-container {
  width: 600px;
}

.integrations-dialog .mat-dialog-container {
  width: 600px;
  padding-bottom: 40px;
}

.sector-dialog .mat-dialog-container {
  width: 400px;
}

.mat-dialog-container {
  border-radius: 20px !important;
}

.mat-dialog-container mat-dialog-actions button {
  padding: 0px 40px;
  margin-right: 5px;
}

@media (max-width: 450px) {
  .mat-dialog-container mat-dialog-actions button {
    padding: 0px 10px;
  }
}

.dialog-table {
  width: 100%;
  overflow-x: auto;
}

.dialog-cancel-btn {
  text-transform: uppercase;
}

.dialog-save-btn {
  color: #fff;
  background: $clr-accent;
  text-transform: uppercase;
}

.dialog-save-btn:disabled {
  cursor: not-allowed !important;
  color: $ff-color-200;
  background-color: $clr-gray-100;
}

/* END DIALOG */

/* START SWAL */
.swal-text {
  text-align: center;
}

.swal-button--confirm {
  background-color: $clr-warn !important;
  color: white !important;
}

.swal-button--confirm:hover {
  background-color: $clr-warn-hover !important;
}

.swal-button--cancel {
  background-color: $clr-white !important;
  color: $ff-color-300 !important;
}

.swal-button--cancel:hover {
  background-color: rgba($color: #424242, $alpha: 0.3) !important;
}

/* END SWAL */

.row {
  display: flex !important;
}

.fx-gap {
  gap: var(--fx-gap);
}

.column {
  display: flex !important;
  flex-direction: column !important;
}

.card-container {
  box-shadow: none !important;
}

.card-title,
.dialog-title {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.p-t-sm {
  padding-top: 10px !important;
}

.p-w-sm {
  padding: 0 10px !important;
}

.m-w-auto {
  margin: 0 auto !important;
}

.m-h-auto {
  margin: auto 0 !important;
}

.m-b-sm {
  margin-bottom: 10px !important;
}

.m-l-sm {
  margin-left: 10px !important;
}

.m-r-sm {
  margin-right: 10px !important;
}

.half-width {
  width: 50% !important;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align: center !important;
}

.spacer {
  flex: 1 1 auto;
}

.btn-primary {
  color: $ff-color-white !important;
  background-color: $clr-primary !important;
}

a.active {
  color: $clr-primary !important;
}

/* START DASHBOARD GRID */

.dashboard-title {
  padding-top: 32px;
  font-size: 20px !important;
  font-weight: 400 !important;
  color: $ff-color-000;
}

.dashboard-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 1200px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
  }
}

/* END DASHBOARD GRID */

/* START CHART CARD */

.chart-card {
  height: auto;
}

.chart-card-content {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;

  div {
    width: 95% !important;
  }
}

.chart-empty {
  background-color: $clr-white;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $clr-gray-100;
  font-size: 35px;
  opacity: 0.5;
  line-height: 32px;
  text-align: center;
}

@media (max-width: 650px) {
  .chart-card {
    height: 280px !important;
  }
}

@media (max-width: 450px) {
  .chart-card {
    height: 200px !important;
  }
}

/* END CHART CARD */

@media (max-width: 650px) {
  .responsive-row {
    gap: 0;
    flex-direction: column !important;
  }
}

/* START SIMPLE-CARD */
.simple-card {
  width: 100%;
  background-color: $ff-color-white;
  border-radius: 15px;
  display: flex;
  height: 150px;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  margin: 20px 0px 20px 0px;
}

.simple-card > .simple-card-item:first-of-type {
  padding-left: 24px;
}

.simple-card > .simple-card-item:last-of-type {
  padding-right: 24px;
}

.simple-card .simple-card-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 140px;
  margin: 10px;
  width: 200px;
  height: 60%;
}

.simple-card .simple-card-item h3 {
  @extend .medium-text-bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54);
}

.simple-card .simple-card-item .simple-card-content {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.simple-card .simple-card-item .simple-card-actions {
  margin-bottom: 5px;
}

.empty-simple-card {
  width: 100%;
  background-color: $ff-color-white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 20px 0px 20px 0px;
}

.empty-simple-card .empty-simple-card-titles {
  display: flex;
  width: 90%;
  justify-content: space-between;
  padding: 20px 0px 10px 0px;
}

.empty-simple-card .empty-simple-card-titles h3 {
  @extend .medium-text-bold;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54);
}

.empty-simple-card .empty-simple-card-titles h3:first-of-type {
  padding-left: 24px;
}

.empty-simple-card .empty-simple-card-titles h3:last-of-type {
  padding-right: 24px;
}

.empty-simple-card .empty-msg {
  @extend .small-text-regular;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 25px 0px;
}

/* END SIMPLE-CARD */

/* START STATUS-CIRCLE */
.active-status::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 8px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: $clr-green-400;
}

.not-active-status::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 8px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: $clr-warn;
}

.not-active-status-grey::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 8px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: $clr-gray-100;
}

/* END STATUS-CIRCLE */

/* START MAT-CHIP */
mat-chip {
  display: flex !important;
  align-items: center !important;
  border-radius: 4px !important;
  font-size: 13px !important;
  background-color: $clr-primary !important;
  color: $clr-white !important;

  mat-icon {
    cursor: pointer !important;
    color: $clr-white !important;
    font-size: 15px !important;
    opacity: 1 !important;
    height: auto !important;
    width: auto !important;
  }
}

/* END MAT-CHIP */

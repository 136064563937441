$clr-primary: #18BFFF;
$clr-accent: #002B77;
$clr-mobi-phone: #3D6DB9;
$clr-warning: #eead2d;
$clr-warn: #EB5757;
$clr-warn-hover: #ca4c4c;
$clr-white: #FFFFFF;
$clr-green-400: #00975C;

$clr-gray-100: #A8A8A8;
$clr-gray-200: #E4E4E4;
$clr-gray-400: #404040;

$ff-color-000: #6F6F6F;
$ff-color-100: #616161;
$ff-color-200: #424242;
$ff-color-300: #242424;
$ff-color-white: #fff;